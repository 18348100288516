import { ReactNode, useEffect, useState } from "react";
import { useSessionState } from "../../hooks/useSessionState";
import { addUser } from "../../redux/actions";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import Page500 from "../auth/Page500";
import { AuthorizeContext } from "../../components/guards/UserAuthenticationGuard";
import Loader from "../../components/Loader";
import { RequestType } from "../../components/withAPI";
import { useAPI } from "../../components/useAPI";
import { IUserExtended } from "../../bffmodels/IUserExtended";
import { useAppDispatch } from "../../redux/hooks";

interface SpreadsheetsUserLoaderProps {
  children: ReactNode;
}

export const SpreadsheetsUserLoader = (props: SpreadsheetsUserLoaderProps) => {
  const { children } = props;

  const api = useAPI();

  const dispatch = useAppDispatch();

  const featureFlags = useFeatureFlags();

  const [userInfo, setUserInfo] = useState<IUserExtended | null>(null);
  const [loading, setLoading] = useState(true);
  const [hadError, setHadError] = useState(false);

  const sessionState = useSessionState();

  async function getCurrentUser() {
    const { payload, status } = await api.callAPI<IUserExtended>(
      RequestType.Get,
      "/users/self",
      "401 - Unauthorized",
    );

    if (status === 200 && payload) {
      setUserInfo(payload);
      dispatch(addUser(payload));
      window.sessionStorage.setItem("userInfo", JSON.stringify(payload));
      featureFlags.setIdentity(payload.id, payload.email, payload.accountId);
    } else {
      setHadError(true);
    }
  }

  useEffect(() => {
    Promise.all([getCurrentUser(), sessionState.initializeSessionState()])
      .then(() => setLoading(false))
      .catch((err) => {
        console.error(err);
        setHadError(true);
      });
  }, []); // eslint-disable-line

  if (hadError) {
    return <Page500 hideReturnToWebsiteButton />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthorizeContext.Provider value={userInfo!}>
      {children}
    </AuthorizeContext.Provider>
  );
};
