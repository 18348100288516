import React from "react";
import { Outlet } from "react-router-dom";

import Main from "../components/Main";
import { Footer } from "../components/Footer";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";
import SimpleHeader from "src/components/SimpleHeader";

interface ISpreadsheetsEditConnectionLayout {
  children?: React.ReactNode;
}

const SpreadsheetsEditConnectionLayout = ({
  children,
}: ISpreadsheetsEditConnectionLayout) => {
  return (
    <>
      <SimpleHeader />
      <Wrapper>
        <Main className={"d-flex w-100 justify-content-center layouts-OEM"}>
          <Content>
            <Outlet />
            {children}
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </>
  );
};

export default SpreadsheetsEditConnectionLayout;
