import { useParams, useSearchParams } from "react-router-dom";
import EmbeddedDocumentation from "../../components/EmbeddedDocumentation";
import { Col, Container, Row } from "reactstrap";
import EditConnection from "../connections/EditConnection";

export const EditSpreadsheetsConnection = () => {
  const [searchParams] = useSearchParams();
  const { connectionId } = useParams();

  const driverType = searchParams.get("driver")!;

  return (
    <Container fluid className="pages-edit-oem-connection">
      <Row>
        <Col className="column-edit-connection">
          <EditConnection
            connectionId={connectionId}
            driverType={driverType}
            isOEMConnection={false}
            fromSpreadsheetsClient={true}
          />
        </Col>
        <EmbeddedDocumentation driver={driverType} />
      </Row>
    </Container>
  );
};
