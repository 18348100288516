import React from "react";
import { Route, Routes } from "react-router-dom";
import Page404 from "../auth/Page404";
import SpreadsheetsEditConnectionLayout from "../../layouts/SpreadsheetsEditConnectionLayout";
import { EditSpreadsheetsConnection } from "./EditSpreadsheetsConnection";
import { SpreadsheetsUserLoader } from "./SpreadsheetsUserLoader";

const SpreadsheetsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SpreadsheetsUserLoader>
            <SpreadsheetsEditConnectionLayout />
          </SpreadsheetsUserLoader>
        }
      >
        <Route
          path="connections/edit"
          element={<EditSpreadsheetsConnection />}
        />
        <Route
          path="connections/edit/:connectionId"
          element={<EditSpreadsheetsConnection />}
        />

        <Route path="" element={<Page404 showBranding={false} />} />
      </Route>

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default SpreadsheetsRoutes;
